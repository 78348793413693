@use 'sass:math';

@mixin vw-font-size($px-size, $min-px-size: null, $max-px-size: null, $as-at: $design-width) {
  $unitless-vw-size: math.div($px-size, $as-at) * 100;
  $stop-shrinking-at: if($min-px-size, round(math.div($as-at * $min-px-size, $px-size)), null);
  $start-shrinking-at: if($max-px-size, round(math.div($as-at * $max-px-size, $px-size)), null);

  @if $stop-shrinking-at {
    @media (max-width: $stop-shrinking-at) {
      font-size: math.div($stop-shrinking-at * $unitless-vw-size, 100);
    }

    @if $start-shrinking-at {
      @media (min-width: #{$stop-shrinking-at + 1px}) and
             (max-width: #{$start-shrinking-at - 1px}) {
        font-size: #{$unitless-vw-size + 'vw'};
      }

      @media (min-width: $start-shrinking-at) {
        font-size: math.div($start-shrinking-at * $unitless-vw-size, 100);
      }
    } @else {
      @media (min-width: #{$stop-shrinking-at + 1}) {
        font-size: #{$unitless-vw-size + 'vw'};
      }
    }
  } @else if $start-shrinking-at {
    @media (max-width: #{$start-shrinking-at - 1}) {
      font-size: #{$unitless-vw-size + 'vw'};
    }

    @media (min-width: $start-shrinking-at) {
      font-size: $start-shrinking-at * $unitless-vw-size / 100;
    }
  } @else {
    font-size: $px-size;
  }
}

// h1, h2 etc mixins are useful when you need an h2 to look like an h3 or something.
@mixin body-x-small {
  font-family: var(--font-primary);
  font-size: 8px;
  line-height: 1.2;
  font-style: normal;
  font-weight: normal;
  @include md {
    font-size: rem(9.5);
    line-height: 1.3;
  }
}

@mixin body-smaller {
  font-family: var(--font-primary);
  font-size: 12px;
  line-height: 1.2;
  font-style: normal;
  font-weight: normal;
}

@mixin body-small {
  font-family: var(--font-primary);
  font-size: 11px;
  line-height: 1.2;
  font-style: normal;
  font-weight: normal;
  @include md {
    font-size: max(rem(12), 12px);
    line-height: 1.3;
  }
}
@mixin body {
  font-style: normal;
  font-weight: normal;
  font-family: var(--font-primary);
  font-size: 18px;
  line-height: 1.3;

  letter-spacing: 0.2px;
  @include md {
    font-size: max(rem(15), 12px);
    line-height: 1.3;
  }
}
@mixin body-medium {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.26;
  letter-spacing: 0.2px;
  @include md {
    line-height: 1.3;
    font-size: max(rem(19), 12px);
  }
}
@mixin body-large {
  font-family: var(--font-primary);
  font-size: 20px;
  line-height: 1.3;
  font-style: normal;
  font-weight: normal;
  @include md {
    font-size: rem(21);
    line-height: 1.3;
  }
}

@mixin heading-x-small {
  font-family: var(--font-primary-alt1);
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0.6px;

  @include md {
    line-height: 1.2;
    font-size: rem(30);
    letter-spacing: 0.8px;
  }
}
@mixin heading-x-large {
  font-family: var(--font-primary-alt1);
  font-style: normal;
  font-weight: 600;

  font-size: 65px;
  line-height: 0.8;
  letter-spacing: 0.35px;

  @include md {
    font-size: rem(195);
    line-height: 0.8;
    letter-spacing: 0.35px;
  }
}

@mixin heading-x-large-secondary {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 200px;
  @include md {
    letter-spacing: -8px;
    line-height: 1.28;
    font-size: rem(250);
  }
}

@mixin heading-medium {
  // Your h2 styles here
  font-family: var(--font-primary-alt1);
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 0.8;
  // letter-spacing: 1px;

  @include md {
    letter-spacing: 1.5px;
    line-height: 0.85;
    font-size: rem(80);
  }
}

@mixin heading-medium-light {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-size: 50px;

  @include md {
    line-height: 1;
    font-size: rem(50);
  }
}
@mixin heading-large-light {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 1;
  @include md {
    font-size: rem(80);
    line-height: 1;
  }
}
@mixin heading-small-light {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 1.2;
  @include md {
    line-height: 1.2;
    font-size: rem(40);
  }
}
@mixin carousel-heading {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  @include md {
    line-height: 1.2;
    font-size: rem(40);
  }
}
@mixin quote-large {
  font-family: var(--font-primary-alt1);
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 0.73;
  letter-spacing: 0.5px;
  line-height: 0.85;

  @include md {
    font-size: rem(120);
    letter-spacing: 0.5px;
  }
}
@mixin quote-large-credit {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 22px;

  @include md {
    font-size: rem(40);
    line-height: 0.85;
    letter-spacing: 0.404243px;
  }
}
@mixin quote-large-credit-italic {
  @include quote-large-credit();
  font-style: italic;
}

@mixin quote-medium {
  font-family: var(--font-primary-alt1);
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 0.85;

  @include md {
    font-size: rem(100);
    letter-spacing: 0.5px;
  }
}
@mixin quote-small {
  font-family: var(--font-primary-alt1);
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 0.78;
  letter-spacing: 0.5px;
  @include md {
    font-size: rem(60);
    line-height: 0.85;
    letter-spacing: 0.5px;
  }
}
@mixin side-slug {
  font-family: var(--font-primary-alt2);
  font-style: normal;
  font-weight: normal;

  font-size: 9px;
  line-height: 1.2;
  letter-spacing: 0.3px;

  @include md {
    font-size: rem(12);
    line-height: 1.2;
    letter-spacing: 0.3px;
  }
}
