$breakpoint-sm: 420px;
$breakpoint-md: 768px;
$breakpoint-lg: 1124px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;

@mixin sm {
  @media (max-width: #{$breakpoint-md - 1}) {
    @content;
  }
}
// we used this for TurnerDuckworth special case for a few settings
@mixin customTablet {
  @media (min-width: 768px) and (max-width: 1250px) {
    @content;
  }
}
@mixin tabletOnly {
  @media (max-width: 1024px) and (min-width: 769px) {
    @content;
  }
}

@mixin md {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}
@mixin lg {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
@mixin xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}
