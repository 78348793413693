@import '../scss/components_base';

.mobileLayout {
  @include md {
    display: none;
  }
}
.grid {
  @include grid-standard;
}
.workItem {
  // aspect-ratio: 1;
  // width: 100%;
  text-align: center;
  grid-column-start: 2;
  grid-column-end: -2;
  margin-bottom: 77px;
}
.imageHolder {
  position: relative;
  display: block;
  aspect-ratio: 1;
  width: 100%;
}
.projectTitle {
  @include heading-large-light;
  font-size: 35px;
  margin-top: 20px;
  text-transform: uppercase;
  max-width: 100%;
}
